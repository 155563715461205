import lvl1 from "../IMAGENS/NIVEIS/ESPACO_1.webp";
import lvl2 from "../IMAGENS/NIVEIS/ESPACO_2.webp";
import lvl3 from "../IMAGENS/NIVEIS/ESPACO_3.webp";
import lvl4 from "../IMAGENS/NIVEIS/ESPACO_4.webp";
import lvl5 from "../IMAGENS/NIVEIS/ESPACO_5.webp";
import lvl6 from "../IMAGENS/NIVEIS/ESPACO_6.webp";
import lvl7 from "../IMAGENS/NIVEIS/ESPACO_7.webp";
import lvl8 from "../IMAGENS/NIVEIS/ESPACO_8.webp";
import lvl9 from "../IMAGENS/NIVEIS/ESPACO_9.webp";
import lvl10 from "../IMAGENS/NIVEIS/ESPACO_10.webp";
import lvl11 from "../IMAGENS/NIVEIS/ESPACO_11.webp";
import lvl12 from "../IMAGENS/NIVEIS/ESPACO_12.webp";
import lvl13 from "../IMAGENS/NIVEIS/ESPACO_13.webp";
import lvl14 from "../IMAGENS/NIVEIS/ESPACO_14.webp";
import lvl15 from "../IMAGENS/NIVEIS/ESPACO_15.webp";
import lvl16 from "../IMAGENS/NIVEIS/ESPACO_16.webp";
import lvl17 from "../IMAGENS/NIVEIS/ESPACO_17.webp";
import lvl18 from "../IMAGENS/NIVEIS/ESPACO_18.webp";
import lvl19 from "../IMAGENS/NIVEIS/ESPACO_19.webp";
import lvl20 from "../IMAGENS/NIVEIS/ESPACO_20.webp";
import lvl21 from "../IMAGENS/NIVEIS/ESPACO_21.webp";
import lvl22 from "../IMAGENS/NIVEIS/ESPACO_22.webp";
import lvl23 from "../IMAGENS/NIVEIS/ESPACO_23.webp";
import lvl24 from "../IMAGENS/NIVEIS/ESPACO_24.webp";
import lvl25 from "../IMAGENS/NIVEIS/ESPACO_25.webp";
import lvl26 from "../IMAGENS/NIVEIS/ESPACO_26.webp";
import lvl27 from "../IMAGENS/NIVEIS/ESPACO_27.webp";
import lvl28 from "../IMAGENS/NIVEIS/ESPACO_28.webp";
import lvl29 from "../IMAGENS/NIVEIS/ESPACO_29.webp";
import lvl30 from "../IMAGENS/NIVEIS/ESPACO_30.webp";
import lvl31 from "../IMAGENS/NIVEIS/ESPACO_31.webp";
import lvl32 from "../IMAGENS/NIVEIS/ESPACO_32.webp";
import lvl33 from "../IMAGENS/NIVEIS/ESPACO_33.webp";
import lvl34 from "../IMAGENS/NIVEIS/ESPACO_34.webp";
import lvl35 from "../IMAGENS/NIVEIS/ESPACO_35.webp";
import lvl36 from "../IMAGENS/NIVEIS/ESPACO_36.webp";
import lvl37 from "../IMAGENS/NIVEIS/ESPACO_37.webp";
import lvl38 from "../IMAGENS/NIVEIS/ESPACO_38.webp";
import lvl39 from "../IMAGENS/NIVEIS/ESPACO_39.webp";
import lvl40 from "../IMAGENS/NIVEIS/ESPACO_40.webp";
import lvl41 from "../IMAGENS/NIVEIS/ESPACO_41.webp";
import lvl42 from "../IMAGENS/NIVEIS/ESPACO_42.webp";
import lvl43 from "../IMAGENS/NIVEIS/ESPACO_43.webp";
import lvl44 from "../IMAGENS/NIVEIS/ESPACO_44.webp";
import lvl45 from "../IMAGENS/NIVEIS/ESPACO_45.webp";
import lvl46 from "../IMAGENS/NIVEIS/ESPACO_46.webp";
import lvl47 from "../IMAGENS/NIVEIS/ESPACO_47.webp";
import lvl48 from "../IMAGENS/NIVEIS/ESPACO_48.webp";
import lvl49 from "../IMAGENS/NIVEIS/ESPACO_49.webp";
import lvl50 from "../IMAGENS/NIVEIS/ESPACO_50.webp";

export const imageData = [
  {
    id: 1,
    image: lvl1,
    title: "Nível 1",
    description: "SOM... I AM YOUR FATHER.",
    isFree: false,
  },
  {
    id: 2,
    image: lvl2,
    title: "Nível 2",
    description: "SOM... MARCHA IMPERIAL.",
    isFree: true,
  },
  {
    id: 3,
    image: lvl3,
    title: "Nível 3",
    description: "SOM... STAR WARS BY PETER GRIFIN.",
    isFree: false,
  },
  {
    id: 4,
    image: lvl4,
    title: "Nível 4",
    description: "SOM... STAR WARS DISCO DANCE.",
    isFree: true,
  },
  {
    id: 5,
    image: lvl5,
    title: "Nível 5",
    description: "SOM... ROCK VADER.",
    isFree: false,
  },
  {
    id: 6,
    image: lvl6,
    title: "Nível 6",
    description: "SOM... CHEWACCA.",
    isFree: true,
  },
  {
    id: 7,
    image: lvl7,
    title: "Nível 7",
    description: "1ª MASCARA QUE DÁ UM JOGO",
    isFree: true,
  },
  {
    id: 8,
    image: lvl8,
    title: "Nível 8",
    description: "VIDEO - STORM TROOPER LIGHTSABER.",
    isFree: false,
  },
  {
    id: 9,
    image: lvl9,
    title: "Nível 9",
    description: "SOM... RESPIRAR DARTH VADER.",
    isFree: true,
  },
  {
    id: 10,
    image: lvl10,
    title: "Nível 10",
    description: "SOM... MANDALORIAN GUITAR.",
    isFree: false,
  },
  {
    id: 11,
    image: lvl11,
    title: "Nível 11",
    description: "SOM - THE POWER OF THE DARK SIDE",
    isFree: false,
  },
  {
    id: 12,
    image: lvl12,
    title: "Nível 12",
    description: "SOM - R2-D2",
    isFree: false,
  },
  {
    id: 13,
    image: lvl13,
    title: "Nível 13",
    description: "2ª MASCARA QUE DÁ UM JOGO",
    isFree: true,
  },
  {
    id: 14,
    image: lvl14,
    title: "Nível 14",
    description: "TIME OUT",
    isFree: false,
  },
  {
    id: 15,
    image: lvl15,
    title: "Nível 15",
    description: "STRONG MR. PEPPER - Come 5 Mr. Pepper.",
    isFree: true,
  },
  {
    id: 16,
    image: lvl16,
    title: "Nível 16",
    description: "VIDEO - MUSICA.",
    isFree: false,
  },
  {
    id: 17,
    image: lvl17,
    title: "Nível 17",
    description: "VIDEOS - ANIMAÇÃO.",
    isFree: true,
  },
  {
    id: 18,
    image: lvl18,
    title: "Nível 18",
    description: "SOM - CHEWBACCA SAD.",
    isFree: true,
  },
  {
    id: 19,
    image: lvl19,
    title: "Nível 19",
    description: "3ª MASCARA QUE DÁ UM JOGO",
    isFree: true,
  },
  {
    id: 20,
    image: lvl20,
    title: "Nível 20",
    description: "SOM - BLASTER SOUND.",
    isFree: false,
  },
  {
    id: 21,
    image: lvl21,
    title: "Nível 21",
    description: "SOM - DARTH VADER NOOOOOOOOO.",
    isFree: false,
  },
  {
    id: 22,
    image: lvl22,
    title: "Nível 22",
    description: "SOM - IT´S A TRAP.",
    isFree: false,
  },
  {
    id: 23,
    image: lvl23,
    title: "Nível 23",
    description: "SOM - 3C-PO.",
    isFree: true,
  },
  {
    id: 24,
    image: lvl24,
    title: "Nível 24",
    description: "SOM - JAR JAR AOS GRITOS.",
    isFree: true,
  },
  {
    id: 25,
    image: lvl25,
    title: "Nível 25",
    description: "VIDEO... CAT VADER",
    isFree: false,
  },
  {
    id: 26,
    image: lvl26,
    title: "Nível 26",
    description: "4ª MASCARA QUE DÁ UM JOGO",
    isFree: true,
  },
  {
    id: 27,
    image: lvl27,
    title: "Nível 27",
    description: "VIDEO - MUSICA.",
    isFree: false,
  },
  {
    id: 28,
    image: lvl28,
    title: "Nível 28",
    description: "SOM - ANAKIN.",
    isFree: true,
  },
  {
    id: 29,
    image: lvl29,
    title: "Nível 29",
    description: "SOM - YODA.",
    isFree: false,
  },
  {
    id: 30,
    image: lvl30,
    title: "Nível 30",
    description: "STRONG MR. PEPPER - Come 5 Mr. Pepper.",
    isFree: false,
  },
  {
    id: 31,
    image: lvl31,
    title: "Nível 31",
    description: "EFEITO - INTERFERÊNCIA.",
    isFree: true,
  },
  {
    id: 32,
    image: lvl32,
    title: "Nível 32",
    description: "GANHAS-TE 200 MOEDAS (VÃO SER ENTREGUES NO FINAL DA STREAM).",
    isFree: true,
  },
  {
    id: 33,
    image: lvl33,
    title: "Nível 33",
    description: "5ª MASCARA QUE DÁ UM JOGO",
    isFree: true,
  },
  {
    id: 34,
    image: lvl34,
    title: "Nível 34",
    description: "DECIDE O PROXIMO EMOTE DO CANAL (SÓ UM PEDIDO).",
    isFree: true,
  },
  {
    id: 35,
    image: lvl35,
    title: "Nível 35",
    description: "DESAFIO PHOTOSHOP (2 NO MÁXIMO).",
    isFree: false,
  },
  {
    id: 36,
    image: lvl36,
    title: "Nível 36",
    description: "EFEITO - RETRO.",
    isFree: true,
  },
  {
    id: 37,
    image: lvl37,
    title: "Nível 37",
    description: "VIDEO - MUSICA.",
    isFree: false,
  },
  {
    id: 38,
    image: lvl38,
    title: "Nível 38",
    description: "GANHAS-TE 200 MOEDAS (VÃO SER ENTREGUES NO FINAL DA STREAM).",
    isFree: true,
  },
  {
    id: 39,
    image: lvl39,
    title: "Nível 39",
    description: "EFEITO - GLITCH.",
    isFree: true,
  },
  {
    id: 40,
    image: lvl40,
    title: "Nível 40",
    description: "6ª MASCARA QUE DÁ UM JOGO",
    isFree: true,
  },
  {
    id: 41,
    image: lvl41,
    title: "Nível 41",
    description: "20% DESCONTO EM FIGURAS DO MEU KO-FI",
    isFree: true,
  },
  {
    id: 42,
    image: lvl42,
    title: "Nível 42",
    description: "EM DESENVOLVIMENTO...",
    isFree: false,
  },
  {
    id: 43,
    image: lvl43,
    title: "Nível 43",
    description: "EFEITO - CHUVA.",
    isFree: true,
  },
  {
    id: 44,
    image: lvl44,
    title: "Nível 44",
    description: "GANHAS-TE 200 MOEDAS (VÃO SER ENTREGUES NO FINAL DA STREAM).",
    isFree: true,
  },
  {
    id: 45,
    image: lvl45,
    title: "Nível 45",
    description: "STRONG MR. PEPPER - Come 5 Mr. Pepper.",
    isFree: true,
  },
  {
    id: 46,
    image: lvl46,
    title: "Nível 46",
    description: "VIDEO - MUSICA.",
    isFree: false,
  },
  {
    id: 47,
    image: lvl47,
    title: "Nível 47",
    description: "7ª MASCARA QUE DÁ UM JOGO",
    isFree: true,
  },
  {
    id: 48,
    image: lvl48,
    title: "Nível 48",
    description: "EM DESENVOLVIMENTO...",
    isFree: false,
  },
  {
    id: 49,
    image: lvl49,
    title: "Nível 49",
    description: "GIVEAWAY",
    isFree: true,
  },
  {
    id: 50,
    image: lvl50,
    title: "Nível 50",
    description: "GIVEAWAY SÓ PARA SUBS",
    isFree: false,
  },
];
